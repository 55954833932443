<script>
const api_url='https://xxxxx.xxx.xxx';
const vote_list=[
  {
    "title": "籃球",
    "title_en": "Basketball",
    "img": "https://i.imgur.com/3oRQ78z.jpg",
    "url": "/vote/1",
  },
  {
    "title": "排球",
    "title_en": "Volleyball",
    "img": "https://i.imgur.com/QwCrMbf.jpg",
    "url": "/vote/2",
  },
  {
    "title": "羽球",
    "title_en": "Badminton",
    "img": "https://i.imgur.com/NNpPng6.jpg",
    "url": "/vote/3",
  },
  // {
  //   "title": "桌球",
  //   "title_en": "Table Tennis",
  //   "img": "https://i.imgur.com/3PXcU3Z.jpg",
  //   "url": "/vote/4",
  // },
  {
    "title": "壘球",
    "title_en": "Softball",
    "img": "https://i.imgur.com/0BwYCok.jpg",
    "url": "/vote/5",
  },
]
const leader_board_data=[
  {
    "player": [
      {
        "index": "1", 
        "name": "郭子綾 - 金融三", 
        "place": "女籃", 
        "votes": "270"
      }, 
      {
        "index": "2", 
        "name": "詹宜庭 (北大郭雪芙) - 電機四", 
        "place": "女籃", 
        "votes": "259"
      }, 
      {
        "index": "3", 
        "name": "陳昕慈 (應電通車神) - 應外三", 
        "place": "女籃", 
        "votes": "201"
      }, 
      {
        "index": "4", 
        "name": "陳姿羽 - 會計三", 
        "place": "女籃", 
        "votes": "169"
      }, 
      {
        "index": "5", 
        "name": "賴慧揚 - 電機四", 
        "place": "女籃", 
        "votes": "159"
      }, 
      {
        "index": "6", 
        "name": "邱翊慈 - 不動四", 
        "place": "女籃", 
        "votes": "155"
      }, 
      {
        "index": "7", 
        "name": "田定加 (田田) - 休運一", 
        "place": "女籃", 
        "votes": "138"
      }, 
      {
        "index": "8", 
        "name": "黃亮瑄 - 休運三", 
        "place": "女籃", 
        "votes": "129"
      }, 
      {
        "index": "9", 
        "name": "簡文潔 (MoonJ) - 休運三", 
        "place": "女籃", 
        "votes": "117"
      }, 
      {
        "index": "10", 
        "name": "林芯羽 - 休運二", 
        "place": "女籃", 
        "votes": "116"
      }, 
      {
        "index": "11", 
        "name": "陳姸廷 - 會計三", 
        "place": "女籃", 
        "votes": "115"
      }, 
      {
        "index": "12", 
        "name": "趙妤倫 - 不動四", 
        "place": "女籃", 
        "votes": "114"
      }, 
      {
        "index": "13", 
        "name": "游純瑜 (小游) - 金融四", 
        "place": "女籃", 
        "votes": "112"
      }, 
      {
        "index": "14", 
        "name": "唐靖媛 (湯圓) - 統計四", 
        "place": "女籃", 
        "votes": "109"
      }, 
      {
        "index": "15", 
        "name": "李雨璇 - 法律三", 
        "place": "女籃", 
        "votes": "101"
      }, 
      {
        "index": "16", 
        "name": "姜胤汝 (姜姜) - 社學三", 
        "place": "女籃", 
        "votes": "99"
      }, 
      {
        "index": "17", 
        "name": "陳俞安 - 社學二", 
        "place": "女籃", 
        "votes": "98"
      }, 
      {
        "index": "18", 
        "name": "余采叡 - 社學四", 
        "place": "女籃", 
        "votes": "97"
      }, 
      {
        "index": "19", 
        "name": "林映嫺 (林喜德) - 法律三", 
        "place": "女籃", 
        "votes": "97"
      }, 
      {
        "index": "20", 
        "name": "廖尹瑄 (小廖) - 司法二", 
        "place": "女籃", 
        "votes": "96"
      }, 
      {
        "index": "21", 
        "name": "廖育旻 (玉米) - 社工二", 
        "place": "女籃", 
        "votes": "95"
      }, 
      {
        "index": "22", 
        "name": "黃郁茹 (小茹) - 經濟一", 
        "place": "女籃", 
        "votes": "82"
      }
    ], 
    "title": "女籃"
  }, 
  {
    "player": [
      {
        "index": "1", 
        "name": "張家晉 - 社工三", 
        "place": "男籃 - 前場", 
        "votes": "191"
      }, 
      {
        "index": "2", 
        "name": "陳彥安 (休運KD) - 休運四", 
        "place": "男籃 - 前場", 
        "votes": "166"
      }, 
      {
        "index": "3", 
        "name": "黃麒洋 (Andy) - 司法三", 
        "place": "男籃 - 前場", 
        "votes": "150"
      }, 
      {
        "index": "4", 
        "name": "林弘翌 (下視丘) - 休運三", 
        "place": "男籃 - 後場", 
        "votes": "149"
      }, 
      {
        "index": "5", 
        "name": "周世加 - 企管二", 
        "place": "男籃 - 後場", 
        "votes": "146"
      }, 
      {
        "index": "6", 
        "name": "王銘哲 (魔獸) - 休運三", 
        "place": "男籃 - 前場", 
        "votes": "146"
      }, 
      {
        "index": "7", 
        "name": "林治宇 - 財法四", 
        "place": "男籃 - 後場", 
        "votes": "142"
      }, 
      {
        "index": "8", 
        "name": "莊晉溢 - 法碩一", 
        "place": "男籃 - 前場", 
        "votes": "139"
      }, 
      {
        "index": "9", 
        "name": "葉關兆鈞 (Ya關) - 電機一", 
        "place": "男籃 - 後場", 
        "votes": "138"
      }, 
      {
        "index": "10", 
        "name": "李惟恩 (北大甲二男籃隊長/惟N) - 休運四", 
        "place": "男籃 - 後場", 
        "votes": "135"
      }, 
      {
        "index": "11", 
        "name": "連紹言 - 企管四", 
        "place": "男籃 - 後場", 
        "votes": "130"
      }, 
      {
        "index": "12", 
        "name": "藍永濬 (小藍) - 休運三", 
        "place": "男籃 - 前場", 
        "votes": "129"
      }, 
      {
        "index": "13", 
        "name": "曾家樂 - 統計二", 
        "place": "男籃 - 前場", 
        "votes": "121"
      }, 
      {
        "index": "14", 
        "name": "周彥廷 - 財政二", 
        "place": "男籃 - 後場", 
        "votes": "113"
      }, 
      {
        "index": "15", 
        "name": "李皓暐 (小暐) - 企管二", 
        "place": "男籃 - 前場", 
        "votes": "111"
      }, 
      {
        "index": "16", 
        "name": "王子豪 - 企管三", 
        "place": "男籃 - 後場", 
        "votes": "103"
      }, 
      {
        "index": "17", 
        "name": "張宇証 (小紫) - 休運一", 
        "place": "男籃 - 後場", 
        "votes": "103"
      }, 
      {
        "index": "18", 
        "name": "張昇揚 (電機tatum 電機kobe) - 電機三", 
        "place": "男籃 - 前場", 
        "votes": "95"
      }, 
      {
        "index": "19", 
        "name": "涂佳豪 (籃球場上的音樂詩人、傳球精靈) - 中文三", 
        "place": "男籃 - 前場", 
        "votes": "93"
      }, 
      {
        "index": "20", 
        "name": "洪晟淵 (北大歐尼爾) - 休運四", 
        "place": "男籃 - 前場", 
        "votes": "86"
      }, 
      {
        "index": "21", 
        "name": "黃聿謙 - 社研碩一", 
        "place": "男籃 - 前場", 
        "votes": "67"
      }, 
      {
        "index": "22", 
        "name": "歐昀洋 (Steven) - 法碩二", 
        "place": "男籃 - 後場", 
        "votes": "58"
      }, 
      {
        "index": "23", 
        "name": "賀德弘 - 社學二", 
        "place": "男籃 - 後場", 
        "votes": "57"
      }
    ], 
    "title": "男籃"
  }, 
  {
    "player": [
      {
        "index": "1", 
        "name": "陳映頻 (蘋果) - 統計五", 
        "place": "女排 - 攻擊", 
        "votes": "252"
      }, 
      {
        "index": "2", 
        "name": "李方晴 (經濟抽獎系球員) - 經濟四", 
        "place": "女排 - 攻擊", 
        "votes": "242"
      }, 
      {
        "index": "3", 
        "name": "盧怡廷 (Lulu) - 休運二", 
        "place": "女排 - 攻擊", 
        "votes": "235"
      }, 
      {
        "index": "4", 
        "name": "黃亭云 (黃亭亭) - 社工四", 
        "place": "女排 - 攻擊", 
        "votes": "193"
      }, 
      {
        "index": "5", 
        "name": "王筱筑 (一坨筱筑) - 應外四", 
        "place": "女排 - 攻擊", 
        "votes": "191"
      }, 
      {
        "index": "6", 
        "name": "李心綾 - 企管四", 
        "place": "女排 - 舉球", 
        "votes": "190"
      }, 
      {
        "index": "7", 
        "name": "方筱媛 - 不動碩一", 
        "place": "女排 - 舉球", 
        "votes": "186"
      }, 
      {
        "index": "8", 
        "name": "李季芙 (巨神兵) - 經濟三", 
        "place": "女排 - 攻擊", 
        "votes": "175"
      }, 
      {
        "index": "9", 
        "name": "楊佳容 (yang) - 社工四", 
        "place": "女排 - 攻擊", 
        "votes": "161"
      }, 
      {
        "index": "10", 
        "name": "黃子庭 - 社工四", 
        "place": "女排 - 舉球", 
        "votes": "153"
      }, 
      {
        "index": "11", 
        "name": "游乃衡 - 中文四", 
        "place": "女排 - 攻擊", 
        "votes": "150"
      }, 
      {
        "index": "12", 
        "name": "張家瑋 - 社工四", 
        "place": "女排 - 舉球", 
        "votes": "149"
      }, 
      {
        "index": "13", 
        "name": "鄭友瑄 - 中文三", 
        "place": "女排 - 攻擊", 
        "votes": "142"
      }, 
      {
        "index": "14", 
        "name": "陳心柔 (柱柱) - 休運三", 
        "place": "女排 - 攻擊", 
        "votes": "141"
      }, 
      {
        "index": "15", 
        "name": "鍾郁儀 (嚕嚕) - 不動", 
        "place": "女排 - 攻擊", 
        "votes": "109"
      }, 
      {
        "index": "16", 
        "name": "彭翌華 - 休運二", 
        "place": "女排 - 攻擊", 
        "votes": "108"
      }
    ], 
    "title": "女排"
  }, 
  {
    "player": [
      {
        "index": "1", 
        "name": "陳俊銘 (北大老居米) - 社工研三", 
        "place": "男排 - 自由", 
        "votes": "234"
      }, 
      {
        "index": "2", 
        "name": "錢韞文 (錢包) - 休運二", 
        "place": "男排 - 舉球", 
        "votes": "233"
      }, 
      {
        "index": "3", 
        "name": "林廷賢 - 電機三", 
        "place": "男排 - 邊線攻擊", 
        "votes": "210"
      }, 
      {
        "index": "4", 
        "name": "陳品翰 (北大橫著走) - 企管四", 
        "place": "男排 - 舉球", 
        "votes": "204"
      }, 
      {
        "index": "5", 
        "name": "蔡延隆 (阿隆) - 電機四", 
        "place": "男排 - 邊線攻擊", 
        "votes": "198"
      }, 
      {
        "index": "6", 
        "name": "李茂齊 (統計++9) - 統計三", 
        "place": "男排 - 欄中", 
        "votes": "165"
      }, 
      {
        "index": "7", 
        "name": "郭力愷 (阿愷) - 休運三", 
        "place": "男排 - 邊線攻擊", 
        "votes": "163"
      }, 
      {
        "index": "8", 
        "name": "陳宗翰 - 社工四", 
        "place": "男排 - 邊線攻擊", 
        "votes": "161"
      }, 
      {
        "index": "9", 
        "name": "劉柏宏 (北大企管系草) - 企管二", 
        "place": "男排 - 邊線攻擊", 
        "votes": "160"
      }, 
      {
        "index": "10", 
        "name": "謝承洋 (阿洋) - 企管三", 
        "place": "男排 - 邊線攻擊", 
        "votes": "151"
      }, 
      {
        "index": "11", 
        "name": "王為正 - 休運四", 
        "place": "男排 - 欄中", 
        "votes": "148"
      }, 
      {
        "index": "12", 
        "name": "陶德明 (大南港犽宿神) - 休運二", 
        "place": "男排 - 欄中", 
        "votes": "139"
      }, 
      {
        "index": "13", 
        "name": "林季賢 (法院壁虎幼年期) - 司法二", 
        "place": "男排 - 欄中", 
        "votes": "135"
      }, 
      {
        "index": "14", 
        "name": "陳祈宏 (7宏) - 經濟四", 
        "place": "男排 - 邊線攻擊", 
        "votes": "133"
      }, 
      {
        "index": "15", 
        "name": "楊濰源 - 不動四", 
        "place": "男排 - 欄中", 
        "votes": "132"
      }, 
      {
        "index": "16", 
        "name": "吳秉衡 (踢蛋我最強) - 通訊一", 
        "place": "男排 - 欄中", 
        "votes": "118"
      }, 
      {
        "index": "17", 
        "name": "曹子儀 - 司法二", 
        "place": "男排 - 自由", 
        "votes": "115"
      }, 
      {
        "index": "18", 
        "name": "林建霖 (內中李榮浩) - 休運一", 
        "place": "男排 - 邊線攻擊", 
        "votes": "99"
      }, 
      {
        "index": "19", 
        "name": "陳泊戎 - 財法二", 
        "place": "男排 - 邊線攻擊", 
        "votes": "88"
      }, 
      {
        "index": "20", 
        "name": "陳仕東 (陳東) - 企管", 
        "place": "男排 - 欄中", 
        "votes": "81"
      }, 
      {
        "index": "21", 
        "name": "林柏均 - 資工四", 
        "place": "男排 - 欄中", 
        "votes": "79"
      }, 
      {
        "index": "22", 
        "name": "彭靖為 (彭彭) - 休運二", 
        "place": "男排 - 欄中", 
        "votes": "78"
      }, 
      {
        "index": "23", 
        "name": "朱庭佑 - 資工三", 
        "place": "男排 - 邊線攻擊", 
        "votes": "72"
      }, 
      {
        "index": "24", 
        "name": "林祈穎 - 不動三", 
        "place": "男排 - 自由", 
        "votes": "71"
      }, 
      {
        "index": "25", 
        "name": "陳品諺 - 不動三", 
        "place": "男排 - 邊線攻擊", 
        "votes": "66"
      }, 
      {
        "index": "26", 
        "name": "郭哲瑋 (吊球長) - 經濟一", 
        "place": "男排 - 邊線攻擊", 
        "votes": "47"
      }, 
      {
        "index": "27", 
        "name": "馬皜展 (小馬) - 經濟一", 
        "place": "男排 - 舉球", 
        "votes": "45"
      }
    ], 
    "title": "男排"
  }, 
  {
    "player": [
      {
        "index": "1", 
        "name": "陳映潔 (stef) - 應外二", 
        "place": "女羽", 
        "votes": "197"
      }, 
      {
        "index": "2", 
        "name": "李蘊庭 - 會計三", 
        "place": "男羽", 
        "votes": "149"
      }, 
      {
        "index": "3", 
        "name": "吳書葶 (晶晶) - 應外三", 
        "place": "女羽", 
        "votes": "139"
      }, 
      {
        "index": "4", 
        "name": "楊琪琮 (7bug) - 電機四", 
        "place": "男羽", 
        "votes": "139"
      }, 
      {
        "index": "5", 
        "name": "廖于晴 (魚鰭、晴晴) - 會計二", 
        "place": "女羽", 
        "votes": "137"
      }, 
      {
        "index": "6", 
        "name": "王昱翔 (吹落山風長大的孩子) - 公行二", 
        "place": "男羽", 
        "votes": "131"
      }, 
      {
        "index": "7", 
        "name": "林鈺展 - 電機三", 
        "place": "男羽", 
        "votes": "129"
      }, 
      {
        "index": "8", 
        "name": "董謹睿 - 司法二", 
        "place": "男羽", 
        "votes": "127"
      }, 
      {
        "index": "9", 
        "name": "黃溢謙 (羅蘭) - 會計四", 
        "place": "男羽", 
        "votes": "126"
      }, 
      {
        "index": "10", 
        "name": "許瑋珊 (蛋餅) - 財法二", 
        "place": "女羽", 
        "votes": "123"
      }, 
      {
        "index": "11", 
        "name": "謝睿彥 - 行政二", 
        "place": "男羽", 
        "votes": "114"
      }, 
      {
        "index": "12", 
        "name": "吳芳綺 - 應外三", 
        "place": "女羽", 
        "votes": "113"
      }, 
      {
        "index": "13", 
        "name": "顏致家 - 司法一", 
        "place": "男羽", 
        "votes": "95"
      }, 
      {
        "index": "14", 
        "name": "許弘叡 (韓一我愛你) - 統計二", 
        "place": "男羽", 
        "votes": "89"
      }, 
      {
        "index": "15", 
        "name": "黃顗祺 (Apple) - 中文三", 
        "place": "女羽", 
        "votes": "74"
      }, 
      {
        "index": "16", 
        "name": "唐育謙 (yo謙) - 統碩一", 
        "place": "男羽", 
        "votes": "59"
      }, 
      {
        "index": "17", 
        "name": "楊甯 - 金融一", 
        "place": "女羽", 
        "votes": "52"
      }
    ], 
    "title": "羽球"
  }, 
  {
    "player": [
      {
        "index": "1", 
        "name": "林明緯 (經濟林立) - 經濟四", 
        "place": "內野", 
        "votes": "200"
      }, 
      {
        "index": "2", 
        "name": "戴愷吾 - 會計三", 
        "place": "內野", 
        "votes": "181"
      }, 
      {
        "index": "3", 
        "name": "曾頊 (新的大魔王) - 不動二", 
        "place": "內野", 
        "votes": "153"
      }, 
      {
        "index": "4", 
        "name": "陳蕭喬遠 (北大浪漫-妳浪我漫) - 財政二", 
        "place": "內野", 
        "votes": "147"
      }, 
      {
        "index": "5", 
        "name": "謝易軒 - 統計四", 
        "place": "內野", 
        "votes": "124"
      }, 
      {
        "index": "6", 
        "name": "梁凌佳 (北大周興哲) - 經濟二", 
        "place": "內野", 
        "votes": "102"
      }, 
      {
        "index": "7", 
        "name": "許皓評 (萌え萌えな評評) - 歷史四", 
        "place": "內野", 
        "votes": "95"
      }
    ], 
    "title": "壘球 - 內野"
  }, 
  {
    "player": [
      {
        "index": "1", 
        "name": "劉皓宇 (北大詹子賢) - 會計三", 
        "place": "外野", 
        "votes": "150"
      }, 
      {
        "index": "2", 
        "name": "陳博凱 (PK) - 不動三", 
        "place": "外野", 
        "votes": "139"
      }, 
      {
        "index": "3", 
        "name": "周煥鈞 (北大金城武) - 不動三", 
        "place": "外野", 
        "votes": "132"
      }, 
      {
        "index": "4", 
        "name": "吳秉諭 (小吳) - 會計三", 
        "place": "外野", 
        "votes": "131"
      }, 
      {
        "index": "5", 
        "name": "張晏銘 (北大敗投王) - 通訊三", 
        "place": "外野", 
        "votes": "97"
      }
    ], 
    "title": "壘球 - 外野"
  }, 
  {
    "player": [
      {
        "index": "1", 
        "name": "洪揚晉 - 不動三", 
        "place": "投手", 
        "votes": "117"
      }, 
      {
        "index": "2", 
        "name": "陶俊成 - 統計四", 
        "place": "投手", 
        "votes": "112"
      }, 
      {
        "index": "3", 
        "name": "李亮頤 - 統計二", 
        "place": "投手", 
        "votes": "91"
      }, 
      {
        "index": "4", 
        "name": "向宇謙 - 歷史四", 
        "place": "投手", 
        "votes": "58"
      }
    ], 
    "title": "壘球 - 投手"
  }
]
const Basketball_data={
  "json_list": [
    {
      "player": [
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/PWXKVyX/9684a0755219.jpg", 
          "open": false, 
          "text": "宵夜最愛吃薯球\n 來看綾老師控球\n 詳情請撥：控吧控孔 控球綾 控控孔", 
          "title": "郭子綾 - 金融三", 
          "uuid": "67ef2465-9193-4f2a-ac94-398c382642c4", 
          "vote_url": "/govote/67ef2465-9193-4f2a-ac94-398c382642c4", 
          "votes": "270"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/h86YG4W/89019a5169da.jpg", 
          "open": false, 
          "text": "嗨~我是詹宜庭AKA北大郭雪芙，討厭吃紅蘿蔔，興趣是玩跑跑卡丁車，歡迎加我好友，一起在跑跑世界闖出一片。曾入選三分后，夢想是在明星賽中投三分球!", 
          "title": "詹宜庭 (北大郭雪芙) - 電機四", 
          "uuid": "302d2242-a7b0-476c-a8b1-1fbd960acef9", 
          "vote_url": "/govote/302d2242-a7b0-476c-a8b1-1fbd960acef9", 
          "votes": "259"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/r7rd32p/248909d9ee79.jpg", 
          "open": false, 
          "text": "投我就帶你去兜風😉\n （拜託投我一票啦我想打明星賽）", 
          "title": "陳昕慈 (應電通車神) - 應外三", 
          "uuid": "0cb4cb0c-4e95-4e45-902c-150eafc149ae", 
          "vote_url": "/govote/0cb4cb0c-4e95-4e45-902c-150eafc149ae", 
          "votes": "201"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/WBSvtNg/9fbc0f7f0353.jpg", 
          "open": false, 
          "text": "腰上的肉肉想跟大家打招呼🙋", 
          "title": "陳姿羽 - 會計三", 
          "uuid": "77296023-2b78-40a3-8b8a-51b3241b06bf", 
          "vote_url": "/govote/77296023-2b78-40a3-8b8a-51b3241b06bf", 
          "votes": "169"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/rFfFZs5/974c26af43e2.jpg", 
          "open": false, 
          "text": "大家好，我是賴慧揚，人稱電機凍齡小可愛，只可惜凍在國中...\n 仗著身高優勢，在聯盟賽狂砍18分，\n 想看可愛又迷人的反派角色，記得校慶來看我比賽喔！", 
          "title": "賴慧揚 - 電機四", 
          "uuid": "92b9e601-40f9-4203-929f-b371881be35e", 
          "vote_url": "/govote/92b9e601-40f9-4203-929f-b371881be35e", 
          "votes": "159"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/DYLV8t7/b7fececc074b.jpg", 
          "open": false, 
          "text": "我愛籃球 籃球愛我\n 最後一年 我想打球🏀", 
          "title": "邱翊慈 - 不動四", 
          "uuid": "733b5a12-331c-4e41-bf8f-e35eeab4f17a", 
          "vote_url": "/govote/733b5a12-331c-4e41-bf8f-e35eeab4f17a", 
          "votes": "155"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/JjZhrBQ/d3289186e726.jpg", 
          "open": false, 
          "text": "大家好我叫田定加 畢業於北一女中 現在是休運系的一份子", 
          "title": "田定加 (田田) - 休運一", 
          "uuid": "a6d01664-74ce-42a4-aaa1-f5bc446fa474", 
          "vote_url": "/govote/a6d01664-74ce-42a4-aaa1-f5bc446fa474", 
          "votes": "138"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/QFt9kfQ/a123efad32b3.jpg", 
          "open": false, 
          "text": "大家好我是休運三的黃亮瑄，是被逼著練右手、左手上籃就一直放槍的左撇子", 
          "title": "黃亮瑄 - 休運三", 
          "uuid": "44881cd9-f2e2-453d-9bf9-f400d6b531a1", 
          "vote_url": "/govote/44881cd9-f2e2-453d-9bf9-f400d6b531a1", 
          "votes": "129"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/6NPQ9nb/3d35144ca2fe.jpg", 
          "open": false, 
          "text": "大家好我是休運三的文潔～經過長久努力瘦了13公斤，到明星賽當天預計還能瘦更多，歡迎大家來見證終於瘦下來的我XD", 
          "title": "簡文潔 (MoonJ) - 休運三", 
          "uuid": "5bd41de5-3f66-4fdc-9c08-871512b5cc62", 
          "vote_url": "/govote/5bd41de5-3f66-4fdc-9c08-871512b5cc62", 
          "votes": "117"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/LPfYQrQ/7e8ba6bbd6ee.jpg", 
          "open": false, 
          "text": "大家好我是休運二的林芯羽 我喜歡打籃球", 
          "title": "林芯羽 - 休運二", 
          "uuid": "95f8d62a-e85c-4057-88bf-5e8487c6d123", 
          "vote_url": "/govote/95f8d62a-e85c-4057-88bf-5e8487c6d123", 
          "votes": "116"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/pwktdFF/f241728ce8a6.jpg", 
          "open": false, 
          "text": "盡力去追每一顆球🏀", 
          "title": "陳姸廷 - 會計三", 
          "uuid": "77be921d-f583-4cbc-9aab-5341d192853b", 
          "vote_url": "/govote/77be921d-f583-4cbc-9aab-5341d192853b", 
          "votes": "115"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/N9YL4DF/97ad9de8e34d.jpg", 
          "open": false, 
          "text": "大學最後一年，我要好好打球", 
          "title": "趙妤倫 - 不動四", 
          "uuid": "3eb0b569-fdee-4e0d-8f63-4950ef62f9c5", 
          "vote_url": "/govote/3eb0b569-fdee-4e0d-8f63-4950ef62f9c5", 
          "votes": "114"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/0DdZ7xb/51c03f5f8b43.jpg", 
          "open": false, 
          "text": "嗨我是小游 大四老屁股第一次參加明星賽 希望在場上不要游腔滑調 也不要被晃倒就阿彌陀佛了🌝", 
          "title": "游純瑜 (小游) - 金融四", 
          "uuid": "ef135898-a1f9-4190-8397-c2d3dc410ccd", 
          "vote_url": "/govote/ef135898-a1f9-4190-8397-c2d3dc410ccd", 
          "votes": "112"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/fDntC66/29fea3cab05d.jpg", 
          "open": false, 
          "text": "大家好我是湯圓，大四第一次參加明星賽，請多多指教！", 
          "title": "唐靖媛 (湯圓) - 統計四", 
          "uuid": "f898ab43-d3c3-4840-8f38-6a82724b6cf2", 
          "vote_url": "/govote/f898ab43-d3c3-4840-8f38-6a82724b6cf2", 
          "votes": "109"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/9t7Szgm/c63f858d7d9b.jpg", 
          "open": false, 
          "text": "大家好～我是法律三李雨璇\n 我到現在也不會說自己真的喜歡籃球\n 比起籃球本身 \n 一起打籃球的人好像才是一切的魅力所在\n 即便生活中並不相近\n 上了場大家卻目標一致\n 一起跑 一起玩 一起防守 一起進攻\n 一起 喜歡打籃球", 
          "title": "李雨璇 - 法律三", 
          "uuid": "85f8a7c7-4c30-41bd-8029-0b0b23a9e880", 
          "vote_url": "/govote/85f8a7c7-4c30-41bd-8029-0b0b23a9e880", 
          "votes": "101"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/THWYDyM/58a27bdad441.jpg", 
          "open": false, 
          "text": "愛死社財統🤩", 
          "title": "姜胤汝 (姜姜) - 社學三", 
          "uuid": "224f7119-0fcb-4951-ad95-b47e6e2593c2", 
          "vote_url": "/govote/224f7119-0fcb-4951-ad95-b47e6e2593c2", 
          "votes": "99"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/LJ0vNGn/bb18d08cb671.jpg", 
          "open": false, 
          "text": "哈囉我是社學二的陳俞安。因為我常常一直自投自搶所以每次都被嗆在刷數據(但我真的不是只是很常放槍而已...)我會努力穩定放籃不要雷大家😢", 
          "title": "陳俞安 - 社學二", 
          "uuid": "5de9b920-8128-4cd6-b3de-924bbea1c8e9", 
          "vote_url": "/govote/5de9b920-8128-4cd6-b3de-924bbea1c8e9", 
          "votes": "98"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/Qnd5wXX/eb2a171ff1d7.jpg", 
          "open": false, 
          "text": "嗨嗨\n 很開心可以跟大家打球🏀", 
          "title": "余采叡 - 社學四", 
          "uuid": "1d78a746-e24f-4f38-bd20-9086cdbfc25a", 
          "vote_url": "/govote/1d78a746-e24f-4f38-bd20-9086cdbfc25a", 
          "votes": "97"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/8KRVK8Q/070c9e6667f8.jpg", 
          "open": false, 
          "text": "在家追劇、在學校追球，希望在明星賽能追得到球。", 
          "title": "林映嫺 (林喜德) - 法律三", 
          "uuid": "fe8fb659-5b9e-4022-9387-401ece2baba1", 
          "vote_url": "/govote/fe8fb659-5b9e-4022-9387-401ece2baba1", 
          "votes": "97"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/0qFmhdY/9ef23362cc9f.jpg", 
          "open": false, 
          "text": "我是司法二的小廖！菜味還很重，希望大家打小力一點🥺", 
          "title": "廖尹瑄 (小廖) - 司法二", 
          "uuid": "0a2239f6-48cd-47b2-9c64-acdf6b7533db", 
          "vote_url": "/govote/0a2239f6-48cd-47b2-9c64-acdf6b7533db", 
          "votes": "96"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/zJg6sQV/fd3daf3853e0.jpg", 
          "open": false, 
          "text": "大家好～我是社工二的廖育旻！這是我第一次入選明星賽，希望可以努力盡到我的本分，不要拖累隊友。謝謝大家！", 
          "title": "廖育旻 (玉米) - 社工二", 
          "uuid": "3a985a05-7a7b-48f0-ad95-a2dfc370c6ac", 
          "vote_url": "/govote/3a985a05-7a7b-48f0-ad95-a2dfc370c6ac", 
          "votes": "95"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/vqcVS7P/6213ca2ba848.jpg", 
          "open": false, 
          "text": "小茹報名參加明星賽的原因只有一個\n 為的是跟千芸學姊打球順便學當leader \n 腳步踩穩急停跳投都不是什麼problem \n 最喜歡的招數永遠都是那個crossover \n 但是要學的還很多\n 籃球不是開心就solo\n 如果不想成為豬隊友\n 要讓凱開阿嬤看好我\n 就看齊千芸芳瑜郭子不二家小游\n 來自金經amazing都實力雄厚\n 各位觀眾\n 敬請期待小茹在明星賽的show", 
          "title": "黃郁茹 (小茹) - 經濟一", 
          "uuid": "64f04828-2c79-4768-926a-aa82326b18ab", 
          "vote_url": "/govote/64f04828-2c79-4768-926a-aa82326b18ab", 
          "votes": "82"
        }
      ], 
      "subtitle": "女籃", 
      "vote_num": -1
    }, 
    {
      "player": [
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/F4rBfHS/a723efcd6973.jpg", 
          "open": false, 
          "text": "社工三張家晉，進攻萬花筒，從一號打到五號，身體狀況良好的話有辦法入樽，今年積極健身增加對抗性，臥推以30公斤抓抓感受度為主，甲三男籃前Ace暨前隊長，大雅路pizza hut 外送員，社工系男籃更衣室怪聲，如果想看到KD般的二樓跳投，明星賽投家晉就對了，欣賞他如何教訓防守者！", 
          "title": "張家晉 - 社工三", 
          "uuid": "afc7f01e-b91c-4fad-8f4b-caff551a87fa", 
          "vote_url": "/govote/afc7f01e-b91c-4fad-8f4b-caff551a87fa", 
          "votes": "191"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/ncGf8jd/d2eeb4534ca5.jpg", 
          "open": false, 
          "text": "陳彥安，AKA休運KD，季後賽的PG，不要看他瘦瘦高高，中距離投起來可是毫不手軟。隊友手感冷掉絕對不是因爲投不進，是彥安又講了一個冷笑話。同時身兼甲二男籃跟前休運系學會長，要怎麼不喜歡他呢！", 
          "title": "陳彥安 (休運KD) - 休運四", 
          "uuid": "f7f26838-20e2-4725-b39b-7c89dafa0a7f", 
          "vote_url": "/govote/f7f26838-20e2-4725-b39b-7c89dafa0a7f", 
          "votes": "166"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/vHDxk95/2093b684be1e.jpg", 
          "open": false, 
          "text": "大家好", 
          "title": "黃麒洋 (Andy) - 司法三", 
          "uuid": "ae139ca4-b2f7-48f0-b65f-b9e33598798f", 
          "vote_url": "/govote/ae139ca4-b2f7-48f0-b65f-b9e33598798f", 
          "votes": "150"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/NF1P5ft/178d18271786.jpg", 
          "open": false, 
          "text": "臺北大學聽聞107學年度某天大中午，球場的天空被劃破，牠！出現了，露出臉對球場笑了一下，手中拿molten的牠從縫隙跳了出來，往文院籃框灌下去—— 2年過去了，從此之後三峽居民的希望，臺北大學的安全感，沒錯，牠叫王銘哲「北大魔獸 aka Raz」來自虎尾。\n 魔獸常騎牠的sym菜籃二手車去吃正宗羹王，偶爾也會變劉謙等級的魔術給朋友看，鄰家大男孩的他看似傻大個，上了球場的牠，眼睛會轉換成紅色的，有如馬洛斯一樣鬼龍般化身，想看明星賽的魔獸餵食秀，那就投牠一票吧！", 
          "title": "王銘哲 (魔獸) - 休運三", 
          "uuid": "faf189bf-149c-4aa4-bc71-b8bb6531b19f", 
          "vote_url": "/govote/faf189bf-149c-4aa4-bc71-b8bb6531b19f", 
          "votes": "146"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/Jnpr8CB/0ee845e101c2.jpg", 
          "open": false, 
          "text": "大四那年一堆比賽都沒得打，只好再續約兩年體驗學生籃球。大家多多支持法律四林治宇跟法律三黃麒洋哦，還有我🥺", 
          "title": "莊晉溢 - 法碩一", 
          "uuid": "fa635cd1-34cd-4ab8-b60a-860dd3d89259", 
          "vote_url": "/govote/fa635cd1-34cd-4ab8-b60a-860dd3d89259", 
          "votes": "139"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/hYW7d6z/fccddc0020b3.jpg", 
          "open": false, 
          "text": "大家好，我是藍永濬，191/80，球打得還可以，但游泳不太行。想看我上去硬糙、WaSay、And one的，請支持投一票 !", 
          "title": "藍永濬 (小藍) - 休運三", 
          "uuid": "b53471d7-9d3b-4c7a-a771-8f6f8af2bf0d", 
          "vote_url": "/govote/b53471d7-9d3b-4c7a-a771-8f6f8af2bf0d", 
          "votes": "129"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/9b2zntm/a561dc924996.jpg", 
          "open": false, 
          "text": "大家好", 
          "title": "曾家樂 - 統計二", 
          "uuid": "6644ec77-5b12-426e-b4c5-a1f64091e204", 
          "vote_url": "/govote/6644ec77-5b12-426e-b4c5-a1f64091e204", 
          "votes": "121"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/XJy7Lm1/8101955ef09d.jpg", 
          "open": false, 
          "text": "我是體保生目前就讀台北大學企管系二年級", 
          "title": "李皓暐 (小暐) - 企管二", 
          "uuid": "85b55c23-10ed-478b-90a4-cc76abbf22b2", 
          "vote_url": "/govote/85b55c23-10ed-478b-90a4-cc76abbf22b2", 
          "votes": "111"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/6txgFb5/91b52a618510.jpg", 
          "open": false, 
          "text": "大家好\n 我是電機Kobe昇揚\n 拿到球就是傳籃框\n 傳隊友？那是隊友要做的事", 
          "title": "張昇揚 (電機tatum 電機kobe) - 電機三", 
          "uuid": "afde6b05-0784-4751-8876-b290d86c4ee0", 
          "vote_url": "/govote/afde6b05-0784-4751-8876-b290d86c4ee0", 
          "votes": "95"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/g3XN9GK/c9b12c38784f.jpg", 
          "open": false, 
          "text": "中文三涂佳豪，興趣是打籃球，雖然打得不好，但希望能挑戰看看明星賽。最後，湖人總冠軍🏆！", 
          "title": "涂佳豪 (籃球場上的音樂詩人、傳球精靈) - 中文三", 
          "uuid": "1de2d27c-a65c-490e-b063-a2102c8e3bdc", 
          "vote_url": "/govote/1de2d27c-a65c-490e-b063-a2102c8e3bdc", 
          "votes": "93"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/nr0mCCz/39caef0750bb.jpg", 
          "open": false, 
          "text": "洪晟淵，是一個球技與體重成正比的男人， 基本上認識他的都會稱作他為北大歐尼爾，但是他的罰球穩定度卻遠高於大鯊魚，也因此對上他的時候也需要貼上去不然會被中距離穩定吃分，伴隨著逐漸增長的傳球視野，只要減重成功就會變成北大約柯奇。", 
          "title": "洪晟淵 (北大歐尼爾) - 休運四", 
          "uuid": "51e0f390-3230-4a3a-8d93-9a887bfda98a", 
          "vote_url": "/govote/51e0f390-3230-4a3a-8d93-9a887bfda98a", 
          "votes": "86"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/wpSYDRv/3d099aa78d34.jpg", 
          "open": false, 
          "text": "拜託給個機會", 
          "title": "黃聿謙 - 社研碩一", 
          "uuid": "0608e901-0bd8-46a6-ac19-d7b776756474", 
          "vote_url": "/govote/0608e901-0bd8-46a6-ac19-d7b776756474", 
          "votes": "67"
        }
      ], 
      "subtitle": "男籃 - 前場", 
      "vote_num": -1
    }, 
    {
      "player": [
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/ZT57r0c/feecefd01803.jpg", 
          "open": false, 
          "text": "自帶...Shawn Marion...投籃...特效...，不一定...有Shawn Marion...的...命中率...，明星賽...眾星...雲集...菁驗...難得...，希望...能選上...，懇請...檳友...賜票...。", 
          "title": "林弘翌 (下視丘) - 休運三", 
          "uuid": "0d2a97c1-d85a-4c13-b791-b958c7efcbca", 
          "vote_url": "/govote/0d2a97c1-d85a-4c13-b791-b958c7efcbca", 
          "votes": "149"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/zQ3wbZG/05be7c05ab93.jpg", 
          "open": false, 
          "text": "大家好我是周世加", 
          "title": "周世加 - 企管二", 
          "uuid": "0f193531-fc38-4da3-bfbf-e9de294041d4", 
          "vote_url": "/govote/0f193531-fc38-4da3-bfbf-e9de294041d4", 
          "votes": "146"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/kSGPcmV/c88c4ab88d9c.jpg", 
          "open": false, 
          "text": "大家好我是財法四林治宇aka媽寶渣男，去燒臘店也都只點媽寶飯。今年是我最後一次在明星賽虐妹了，喔不是，是虐人。不過不用擔心，比賽當天媽媽不在，我準備好要大開殺戒！希望各位喜歡我的粉絲們能投我一票！\n （非本人撰寫…大四了，給個機會好嗎🥺）", 
          "title": "林治宇 - 財法四", 
          "uuid": "542eda23-b6bb-4f0b-ae8d-b96078cc2606", 
          "vote_url": "/govote/542eda23-b6bb-4f0b-ae8d-b96078cc2606", 
          "votes": "142"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/ydFtnZj/caa9ac38bf4d.jpg", 
          "open": false, 
          "text": "號稱183\n 葉關 關葉 傻傻分不清楚\n 此人為母胎單 說了你/妳也是不信 偶哭哭\n (;´༎ຶД༎ຶ`)", 
          "title": "葉關兆鈞 (Ya關) - 電機一", 
          "uuid": "d5513ea2-5f7e-4565-96a1-0ce5344ae1e3", 
          "vote_url": "/govote/d5513ea2-5f7e-4565-96a1-0ce5344ae1e3", 
          "votes": "138"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/nw5ntZQ/73813f89d419.jpg", 
          "open": false, 
          "text": "李惟恩，臺北大學甲二籃球隊隊長，他的球風跟他的頭髮一樣變化多端，讓人無法捉摸，他常說場上最重要的就是防守，防守是最簡單也最重要，把票投給他，明星賽highlight不會少", 
          "title": "李惟恩 (北大甲二男籃隊長/惟N) - 休運四", 
          "uuid": "a91fcf2d-0ffa-4b6c-9e1c-ec0dff7ee03b", 
          "vote_url": "/govote/a91fcf2d-0ffa-4b6c-9e1c-ec0dff7ee03b", 
          "votes": "135"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/Gx6TQPq/525ee2ad9e52.jpg", 
          "open": false, 
          "text": "最後一年！", 
          "title": "連紹言 - 企管四", 
          "uuid": "714d816b-d603-490b-abfd-d8cf798880ba", 
          "vote_url": "/govote/714d816b-d603-490b-abfd-d8cf798880ba", 
          "votes": "130"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/HdPxM5P/b41ca881c1e0.jpg", 
          "open": false, 
          "text": "大家好", 
          "title": "周彥廷 - 財政二", 
          "uuid": "4f0001d3-e242-4e27-b379-1b9225cabe16", 
          "vote_url": "/govote/4f0001d3-e242-4e27-b379-1b9225cabe16", 
          "votes": "113"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/Sw1BgsF/9e82d24ce69f.jpg", 
          "open": false, 
          "text": "有三分，絕不投兩分\n 投10中0又怎樣\n 我看到的不是命中率，是勇氣\n 我是王子豪\n Yeah you should know the name", 
          "title": "王子豪 - 企管三", 
          "uuid": "4a6fa71f-652e-4100-9795-e4e108a72893", 
          "vote_url": "/govote/4a6fa71f-652e-4100-9795-e4e108a72893", 
          "votes": "103"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/jhwV1yG/c691adbb6abb.jpg", 
          "open": false, 
          "text": "我叫小紫，專長在旁邊幫學長遞水，希望今年能當稱職的water boy, 在旁邊拖地", 
          "title": "張宇証 (小紫) - 休運一", 
          "uuid": "cac63f18-fd8b-4623-a7d2-4b578a2bc900", 
          "vote_url": "/govote/cac63f18-fd8b-4623-a7d2-4b578a2bc900", 
          "votes": "103"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/M6jQp6V/c693cba6de10.jpg", 
          "open": false, 
          "text": "籃球是青春，我想這就是籃球之於我的存在。\n 從小到大總喜歡打球，儘管在場上身材經常是較瘦弱的那一個、但從不畏懼體格高大的對手，因為我知道只要投籃夠準我一樣能把球放進籃筐。\n 也因此我的進攻手段主要是投籃輔以切入與快攻上籃，如果機會不好還能分球出去，相信這樣的方式能與隊友一起把球打好！", 
          "title": "歐昀洋 (Steven) - 法碩二", 
          "uuid": "4c94d8f7-1d39-47a2-8013-800ae09fe495", 
          "vote_url": "/govote/4c94d8f7-1d39-47a2-8013-800ae09fe495", 
          "votes": "58"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/RQqw4QY/5f2c99a4b833.jpg", 
          "open": false, 
          "text": "讓各位見笑了", 
          "title": "賀德弘 - 社學二", 
          "uuid": "5f59f131-52c5-40c8-80f2-03be25934a44", 
          "vote_url": "/govote/5f59f131-52c5-40c8-80f2-03be25934a44", 
          "votes": "57"
        }
      ], 
      "subtitle": "男籃 - 後場", 
      "vote_num": -1
    }
  ], 
  "title": "籃球"
}
const Volleyball_data={
  "json_list": [
    {
      "player": [
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/DMfkWLM/862944d4f39b.jpg", 
          "open": false, 
          "text": "比賽不愛紮衣服的體保", 
          "title": "李心綾 - 企管四", 
          "uuid": "52ca9063-f12e-41f0-89a6-f55971bffc58", 
          "vote_url": "/govote/52ca9063-f12e-41f0-89a6-f55971bffc58", 
          "votes": "190"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/FwPYxnb/d872c51e09e0.jpg", 
          "open": false, 
          "text": "請大家給不動老人1個機會", 
          "title": "方筱媛 - 不動碩一", 
          "uuid": "73a748ae-522f-40bd-a90a-35fef542ad9a", 
          "vote_url": "/govote/73a748ae-522f-40bd-a90a-35fef542ad9a", 
          "votes": "186"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/PTkW38z/7bee56e1ebbd.jpg", 
          "open": false, 
          "text": "大家好 我是黃子庭 是個運動宅 也是薇寶的班長 歡迎大家一起來加入薇寶點點名的行列！\n 還有一個夢是想打社聯女排 希望大家可以成全我(⁎⁍̴̛ᴗ⁍̴̛⁎)", 
          "title": "黃子庭 - 社工四", 
          "uuid": "a0f0be6c-e952-45de-ae57-d21c163983a4", 
          "vote_url": "/govote/a0f0be6c-e952-45de-ae57-d21c163983a4", 
          "votes": "153"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/5TFYH1x/8b9b3f69006a.jpg", 
          "open": false, 
          "text": "菜菜的舉球，請多指教", 
          "title": "張家瑋 - 社工四", 
          "uuid": "ebd02784-10b3-41ce-83df-0fae456f24fa", 
          "vote_url": "/govote/ebd02784-10b3-41ce-83df-0fae456f24fa", 
          "votes": "149"
        }
      ], 
      "subtitle": "女排 - 舉球", 
      "vote_num": -1
    }, 
    {
      "player": [
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/2MSQNLc/c57217cfd3d4.jpg", 
          "open": false, 
          "text": "台中人。喜歡紫色。\n 家裡有養一隻叫渺渺的貓，很可愛。\n 喜歡開心打球，喜歡打贏比賽。\n 是照片右邊的那個～", 
          "title": "陳映頻 (蘋果) - 統計五", 
          "uuid": "7543e99e-8c8e-4ca8-a1fe-0c88373d3b17", 
          "vote_url": "/govote/7543e99e-8c8e-4ca8-a1fe-0c88373d3b17", 
          "votes": "252"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/thhSw3H/99b055532166.jpg", 
          "open": false, 
          "text": "「經濟四抽獎系球員」\n 不只打球靠運氣，ig也都是tag別人抽獎唷！座右銘：「這世上沒有我標記3個朋友抽不到的東西，如果有，就標記300個」\n 請大家給我一點運氣讓我選上吧♡", 
          "title": "李方晴 (經濟抽獎系球員) - 經濟四", 
          "uuid": "a8695642-8b68-42e6-9e62-793bf78301ba", 
          "vote_url": "/govote/a8695642-8b68-42e6-9e62-793bf78301ba", 
          "votes": "242"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/pxkFYwk/3131cab1460e.jpg", 
          "open": false, 
          "text": "人稱景美扛壩子\n 漂漂亮亮的外表也掩蓋不住他瘋瘋癲癲的個性\n 外表看似冰山美人\n 但內在卻是活潑可愛的大暖女\n 在茫茫人海中如果找不到他\n 只要聽到一個魔性爽朗的笑聲不要懷疑\n 尋聲前往一定能夠找她", 
          "title": "盧怡廷 (Lulu) - 休運二", 
          "uuid": "2e06613b-ee21-417b-a11f-47504f30048b", 
          "vote_url": "/govote/2e06613b-ee21-417b-a11f-47504f30048b", 
          "votes": "235"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/M8g1mc8/58a5c0e82fce.jpg", 
          "open": false, 
          "text": "嗨大家好！\n 希望大家可以圓我一個夢，讓我跟楊佳容同場打球～～", 
          "title": "黃亭云 (黃亭亭) - 社工四", 
          "uuid": "3d7edbfb-d810-4c57-8e6c-3f007b3db4dc", 
          "vote_url": "/govote/3d7edbfb-d810-4c57-8e6c-3f007b3db4dc", 
          "votes": "193"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/9y2wY62/62399ba5d2b8.jpg", 
          "open": false, 
          "text": "大家都說我是一坨筱筑\n 而我真的是", 
          "title": "王筱筑 (一坨筱筑) - 應外四", 
          "uuid": "0db40157-57d9-4afc-be81-8a40f966ca91", 
          "vote_url": "/govote/0db40157-57d9-4afc-be81-8a40f966ca91", 
          "votes": "191"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/BrZy5SP/e99764341271.jpg", 
          "open": false, 
          "text": "人稱「經濟女排巨神兵」，\n 別人站在巨人的肩膀上，\n 而我不需要，\n 因為我就是巨人！", 
          "title": "李季芙 (巨神兵) - 經濟三", 
          "uuid": "ce25a0b3-9af8-4182-b3cd-96affaeb5838", 
          "vote_url": "/govote/ce25a0b3-9af8-4182-b3cd-96affaeb5838", 
          "votes": "175"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/BjHF8jN/c3a5933f8c2c.jpg", 
          "open": false, 
          "text": "嗨大家～我是照片上左邊那個哈哈哈\n 我要畢業了，想跟黃亭亭一起在場上打球，拜託大家圓我一個夢！！！", 
          "title": "楊佳容 (yang) - 社工四", 
          "uuid": "2bf4e77f-33e1-47d5-a719-95465ccaccdb", 
          "vote_url": "/govote/2bf4e77f-33e1-47d5-a719-95465ccaccdb", 
          "votes": "161"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/f8wS5LM/bf1483d68d59.jpg", 
          "open": false, 
          "text": "請大家給我一個機會摸摸明星賽的地板", 
          "title": "游乃衡 - 中文四", 
          "uuid": "175b1c3d-be09-418b-87ed-42a29757eb8c", 
          "vote_url": "/govote/175b1c3d-be09-418b-87ed-42a29757eb8c", 
          "votes": "150"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/3FRw1nw/95d6a4f55761.jpg", 
          "open": false, 
          "text": "嗨，我也不知道要說什麼，如果不想投我，可以投給不動系嚕嚕🌝\n 噢對了，大家快去按讚「親親北大校女排」喲👍", 
          "title": "鄭友瑄 - 中文三", 
          "uuid": "40959972-9723-48f3-8d4d-cf161759c632", 
          "vote_url": "/govote/40959972-9723-48f3-8d4d-cf161759c632", 
          "votes": "142"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/LSwmVRV/100fede0488c.jpg", 
          "open": false, 
          "text": "叫我柱柱就好", 
          "title": "陳心柔 (柱柱) - 休運三", 
          "uuid": "d6f82393-3741-45c1-afd3-88f38406b7e6", 
          "vote_url": "/govote/d6f82393-3741-45c1-afd3-88f38406b7e6", 
          "votes": "141"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/yPTqzYr/9eabe273341d.jpg", 
          "open": false, 
          "text": "哈囉～我是嚕嚕", 
          "title": "鍾郁儀 (嚕嚕) - 不動", 
          "uuid": "5320fcfd-9c4d-4830-be01-b1ab4df40cf0", 
          "vote_url": "/govote/5320fcfd-9c4d-4830-be01-b1ab4df40cf0", 
          "votes": "109"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/gmH1DLX/2315546853d3.jpg", 
          "open": false, 
          "text": "休運女排幹話王\n 如果你覺得打球壓力很大氣氛不好一定是因為你們沒跟他一起打過球，他的幹話可是從入場那刻就沒停過，常常天花亂墜到你會很想把你畢生學到扣球技巧用來打在他身上，不認識的時候還以為就是一根弱不禁風的竹竿，認識後才發現她的跳發攻擊等等全部都看影片自己學的！想要一舉她的風采嗎！明星賽絕對要來看看這個奇葩幹話人物！", 
          "title": "彭翌華 - 休運二", 
          "uuid": "a8be47a2-4823-4916-88a6-2a3fc04f6ccf", 
          "vote_url": "/govote/a8be47a2-4823-4916-88a6-2a3fc04f6ccf", 
          "votes": "108"
        }
      ], 
      "subtitle": "女排 - 攻擊", 
      "vote_num": -1
    }, 
    {
      "player": [
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/3YJMmwg/e64361375978.jpg", 
          "open": false, 
          "text": "我叫錢韞文 畢業於瑞芳高工 舉球員", 
          "title": "錢韞文 (錢包) - 休運二", 
          "uuid": "4567be63-3917-4971-b0e9-c7d1b83c9b0d", 
          "vote_url": "/govote/4567be63-3917-4971-b0e9-c7d1b83c9b0d", 
          "votes": "233"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/1XHDvYk/bd865e04b0dc.jpg", 
          "open": false, 
          "text": "嗨大家好，我是在球場上橫著走的陳品翰\n 你也想橫著走嗎 走吧", 
          "title": "陳品翰 (北大橫著走) - 企管四", 
          "uuid": "b7e82731-15d0-4abf-8dd4-ff060b9f85ef", 
          "vote_url": "/govote/b7e82731-15d0-4abf-8dd4-ff060b9f85ef", 
          "votes": "204"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/nk5Mp4R/f7c075e8eea8.jpg", 
          "open": false, 
          "text": "大家好我是經濟系1A的馬皜展", 
          "title": "馬皜展 (小馬) - 經濟一", 
          "uuid": "9f6ccaa5-2342-45b8-9b22-08dc96a2d7b4", 
          "vote_url": "/govote/9f6ccaa5-2342-45b8-9b22-08dc96a2d7b4", 
          "votes": "45"
        }
      ], 
      "subtitle": "男排 - 舉球", 
      "vote_num": -1
    }, 
    {
      "player": [
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/vsCf9Ww/984f02edf65a.jpg", 
          "open": false, 
          "text": "大家好，我叫李茂齊，aka前統計系學會副會長，我的球速140公里，球路跟頭髮顏色一樣變化多端。\n 我的出現是為了拯救北大低迷的排球風氣，輕鬆扣出猛力的攻擊，每場比賽傲人的功績，連排盟都想把我通緝。", 
          "title": "李茂齊 (統計++9) - 統計三", 
          "uuid": "6ac20d9b-27d5-414b-a529-104819fd9f08", 
          "vote_url": "/govote/6ac20d9b-27d5-414b-a529-104819fd9f08", 
          "votes": "165"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/60zZQfr/8dc023eb5da9.jpg", 
          "open": false, 
          "text": "休運系大四老人家最後的機會，請大家投一票給我🥺", 
          "title": "王為正 - 休運四", 
          "uuid": "dc9814f3-d4d9-44dc-b255-4e78ff03f76c", 
          "vote_url": "/govote/dc9814f3-d4d9-44dc-b255-4e78ff03f76c", 
          "votes": "148"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/zN04s0n/f99b9bc7c224.jpg", 
          "open": false, 
          "text": "哈薩ㄎㄧ 滑一波6666666", 
          "title": "陶德明 (大南港犽宿神) - 休運二", 
          "uuid": "6c20f56e-5402-469b-ba71-7fa524a41656", 
          "vote_url": "/govote/6c20f56e-5402-469b-ba71-7fa524a41656", 
          "votes": "139"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/D9wXLjK/e42f1958f25f.jpg", 
          "open": false, 
          "text": "目前還在幼年期，上場應該只能搞笑，但不打長不大，所以還是報名了。", 
          "title": "林季賢 (法院壁虎幼年期) - 司法二", 
          "uuid": "e85fdeb6-bb3c-4352-8a39-76919476fd08", 
          "vote_url": "/govote/e85fdeb6-bb3c-4352-8a39-76919476fd08", 
          "votes": "135"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/XsSYcVW/38ae6894171a.jpg", 
          "open": false, 
          "text": "嗨，我是常常在球場上晃來晃去，打快攻會亂叫的那個人", 
          "title": "楊濰源 - 不動四", 
          "uuid": "68979819-fb00-4b0e-9096-6ea97066f181", 
          "vote_url": "/govote/68979819-fb00-4b0e-9096-6ea97066f181", 
          "votes": "132"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/93ngXtR/41abac48b2e9.jpg", 
          "open": false, 
          "text": "我是資通跟校隊先發的大二攔中吳秉衡，承洋我大哥，仕東我小弟。", 
          "title": "吳秉衡 (踢蛋我最強) - 通訊一", 
          "uuid": "362b91db-6e8a-4096-8921-50e6cde3c606", 
          "vote_url": "/govote/362b91db-6e8a-4096-8921-50e6cde3c606", 
          "votes": "118"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/61YNxvr/05c5a73ef553.jpg", 
          "open": false, 
          "text": "我不太會跟人溝通喔", 
          "title": "陳仕東 (陳東) - 企管", 
          "uuid": "e43fcf88-fe63-499a-a93d-403c067a05b7", 
          "vote_url": "/govote/e43fcf88-fe63-499a-a93d-403c067a05b7", 
          "votes": "81"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/tXr9SL6/ae3c4065ae47.jpg", 
          "open": false, 
          "text": "大家好，我是個狠角色，我宵夜雞排一次吃兩個，家庭號可樂都一口shot掉，所以我同學都叫我Buffalo，玩遊戲名字都取嘉義金城武，我最愛的女團是吧啦冰吧啦蹦蹦蹦。教我打排球的老師是資工四鄭景鴻。", 
          "title": "林柏均 - 資工四", 
          "uuid": "effff4f4-231f-408b-ae70-3bd16510625d", 
          "vote_url": "/govote/effff4f4-231f-408b-ae70-3bd16510625d", 
          "votes": "79"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/wQjDQCz/1253ca0f21bd.jpg", 
          "open": false, 
          "text": "？", 
          "title": "彭靖為 (彭彭) - 休運二", 
          "uuid": "8356dafe-e219-4ac3-a6d5-abb088db17fa", 
          "vote_url": "/govote/8356dafe-e219-4ac3-a6d5-abb088db17fa", 
          "votes": "78"
        }
      ], 
      "subtitle": "男排 - 欄中", 
      "vote_num": -1
    }, 
    {
      "player": [
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/rmn3F8s/841b6b66e842.jpg", 
          "open": false, 
          "text": "懇求敬老票", 
          "title": "陳俊銘 (北大老居米) - 社工研三", 
          "uuid": "1ffaabeb-5b8a-49df-ae06-314f2baf91d5", 
          "vote_url": "/govote/1ffaabeb-5b8a-49df-ae06-314f2baf91d5", 
          "votes": "234"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/yPfzvWg/67ce2545aa47.jpg", 
          "open": false, 
          "text": "號稱身高171\n 每次接球前都説沒問題\n 噴掉後才説下次一定行\n 對面攻擊還在想宵夜吃哪裡\n 明星賽自由就選法院子儀！", 
          "title": "曹子儀 - 司法二", 
          "uuid": "3a0f8d1f-1dbf-42aa-9d25-1d20c9e3d14b", 
          "vote_url": "/govote/3a0f8d1f-1dbf-42aa-9d25-1d20c9e3d14b", 
          "votes": "115"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/DDTvXbH/3f520d0cbc41.jpg", 
          "open": false, 
          "text": "大家好我是北大地政自由人", 
          "title": "林祈穎 - 不動三", 
          "uuid": "3fed0eca-1fb1-4010-8b73-81a74d05222a", 
          "vote_url": "/govote/3fed0eca-1fb1-4010-8b73-81a74d05222a", 
          "votes": "71"
        }
      ], 
      "subtitle": "男排 - 自由", 
      "vote_num": -1
    }, 
    {
      "player": [
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/Jm6wvct/fd808e123a34.jpg", 
          "open": false, 
          "text": "嗨 我是廷賢", 
          "title": "林廷賢 - 電機三", 
          "uuid": "997da74b-f151-4409-aa56-54a62514b5e5", 
          "vote_url": "/govote/997da74b-f151-4409-aa56-54a62514b5e5", 
          "votes": "210"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/VMtj9KM/9e1e74b52e04.jpg", 
          "open": false, 
          "text": "牡羊座O型，今年21歲。\n 球風是偏向輔助隊上的類型，而不是帥氣的攻擊手，雖然身高183但卻是追求防守最大化的男人，也因此在大三擔任系隊上的舉球員，成為隊友和對手大砲間厚實的鐵壁也穩定攻擊節奏，夢想成為一個讓隊友更能專心攻擊的存在。\n 即便如此，也在校男排磨練自己的攻擊技巧，提升防守，期望看到更加強大的自己！\n 自我介紹到此結束，懇請賜票！", 
          "title": "蔡延隆 (阿隆) - 電機四", 
          "uuid": "e99752a4-2be1-4a80-ae17-1997f5b1a16e", 
          "vote_url": "/govote/e99752a4-2be1-4a80-ae17-1997f5b1a16e", 
          "votes": "198"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/1MZznHr/4023c4fd178f.jpg", 
          "open": false, 
          "text": "正中午的排球場上 身披休運戰袍的球員們正頂著大太陽應戰中 一個又黑又不高的球員從六人中一躍而起 咻~ 一瞬間彷彿不再受地心引力控制的他 跳離了地球表面 懸浮在空中 瞄準了對方的Weak spot 巨聲響起 球似火箭炮一般的砸向對面地板 哨音劃破寂靜 休運郭力愷帶著笑容配上招牌口齒不清喊著口號", 
          "title": "郭力愷 (阿愷) - 休運三", 
          "uuid": "57bd3b39-cc38-43fc-b368-0cf5cfbb5c47", 
          "vote_url": "/govote/57bd3b39-cc38-43fc-b368-0cf5cfbb5c47", 
          "votes": "163"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/QbR76b8/9ff0a2f2f9a3.jpg", 
          "open": false, 
          "text": "宗翰小雞雞今年真的會畢業，\n 拜託大家讓我再玩一次。", 
          "title": "陳宗翰 - 社工四", 
          "uuid": "aff2914f-c48f-4a66-804d-b34ed1ca0f5d", 
          "vote_url": "/govote/aff2914f-c48f-4a66-804d-b34ed1ca0f5d", 
          "votes": "161"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/F5fzDSF/89cc674f3d5a.jpg", 
          "open": false, 
          "text": "排球生涯中穿過不少球衣\n 長春國小1號\n 北安國中1號\n 建國中學1號\n 承記鍋貼3號\n 臺北大學3號\n 而其中，對這顆排球最充滿熱情、最有鬥志的一段時間\n 就是有你們在旁邊為我加油的時候了\n 不干球衣的事", 
          "title": "劉柏宏 (北大企管系草) - 企管二", 
          "uuid": "89fb7fe0-d801-4559-a844-f1ce0215c21a", 
          "vote_url": "/govote/89fb7fe0-d801-4559-a844-f1ce0215c21a", 
          "votes": "160"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/hWtbKyd/e7b684e59575.jpg", 
          "open": false, 
          "text": "來自板橋的全能主攻手\n 最高擊球點：不詳\n 攔網高度：不詳\n 畢業於HVL六連霸的豐原高商\n U20國家隊自由\n 北經女排教練團-主教練\n 甫以自由球員身分入學的承洋，原本是希望入學後能有人打出他守不住的攻擊\n 但是經過一年後發現學校攻擊手水準之低落\n 所以決定以主攻手的身分參加明星賽\n 希望能教教其他攻擊手什麼是攻擊的真諦\n 雖說接嗆司跟喝水一樣\n 但是面對左撇子的攻擊卻有點招架不住\n 已被某天母左撇子攻破心防\n 成為一名每天在想如何叫女友不要再喝可不可的男紙\n 不只在球場上樂於教人，在遊戲上也擅長教育別人\n 例如學長你這走位不太行欸\n 或是學長我們剛剛在會戰你怎麼沒跟等等...\n 希望隊友能學會走位以及團隊精神的熱忱不管在球場上或球場下都是一樣的\n 懇請大家投給北大守護神", 
          "title": "謝承洋 (阿洋) - 企管三", 
          "uuid": "4403e30a-35d5-48af-a174-66bdcec73a10", 
          "vote_url": "/govote/4403e30a-35d5-48af-a174-66bdcec73a10", 
          "votes": "151"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/qyqvLdw/856bd1b9cd50.jpg", 
          "open": false, 
          "text": "經濟先發副攻aka汐止區陳立農\n 「發球掛網少年容，攻擊out ball 陳立農」", 
          "title": "陳祈宏 (7宏) - 經濟四", 
          "uuid": "ce7d2e8a-ce08-4e84-9f86-a81baa547c19", 
          "vote_url": "/govote/ce7d2e8a-ce08-4e84-9f86-a81baa547c19", 
          "votes": "133"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/7XSH5gW/9542d410f0f2.jpg", 
          "open": false, 
          "text": "大一排球體保生 畢業於內湖高中", 
          "title": "林建霖 (內中李榮浩) - 休運一", 
          "uuid": "27185904-aeaf-4199-9a0e-230e8956763c", 
          "vote_url": "/govote/27185904-aeaf-4199-9a0e-230e8956763c", 
          "votes": "99"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/v4MwnHf/ed6023d11ce4.jpg", 
          "open": false, 
          "text": "我來自屏東\n 抽宿舍沒有成功\n 在法律系排走路有風\n 拜託讓我明星賽贏的輕鬆", 
          "title": "陳泊戎 - 財法二", 
          "uuid": "c7529da9-03f2-428d-853e-9bf333eb3cc1", 
          "vote_url": "/govote/c7529da9-03f2-428d-853e-9bf333eb3cc1", 
          "votes": "88"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/9WBLLG3/059023b130af.jpg", 
          "open": false, 
          "text": "大家好我是資工大三系排唯一希望，如果不知道要投誰的話可以投我一票感謝", 
          "title": "朱庭佑 - 資工三", 
          "uuid": "393b5963-51a0-4d8f-996d-7e3bf04106fc", 
          "vote_url": "/govote/393b5963-51a0-4d8f-996d-7e3bf04106fc", 
          "votes": "72"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/RcYcs7K/ada025862a09.jpg", 
          "open": false, 
          "text": "哈囉大家好", 
          "title": "陳品諺 - 不動三", 
          "uuid": "12682a1a-479b-4baf-a4eb-01cc91baadcb", 
          "vote_url": "/govote/12682a1a-479b-4baf-a4eb-01cc91baadcb", 
          "votes": "66"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/tMYt6Qx/00cbca406964.jpg", 
          "open": false, 
          "text": "排球很ez", 
          "title": "郭哲瑋 (吊球長) - 經濟一", 
          "uuid": "a571469e-1466-42d7-84f5-cac34fb36d76", 
          "vote_url": "/govote/a571469e-1466-42d7-84f5-cac34fb36d76", 
          "votes": "47"
        }
      ], 
      "subtitle": "男排 - 邊線攻擊", 
      "vote_num": -1
    }
  ], 
  "title": "排球"
}
const Badminton_data={
  "json_list": [
    {
      "player": [
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/5jCFWX5/195e0675f63f.jpg", 
          "open": false, 
          "text": "嗨！我是應外二的陳映潔：）我在秘魯出生和長大也算台灣人。我從2012年到2018年代表秘魯國家羽球隊出國比賽", 
          "title": "陳映潔 (stef) - 應外二", 
          "uuid": "2bdbdbb2-7ee9-4cac-9deb-dd5538748366", 
          "vote_url": "/govote/2bdbdbb2-7ee9-4cac-9deb-dd5538748366", 
          "votes": "197"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/0mq2R2v/e9d360774335.jpg", 
          "open": false, 
          "text": "大家好，我是吳書葶~\n 國中開始接觸羽球，一碰上就愛不釋手！\n 因為羽球讓我在球場上認識很多朋友，\n 大家互相切磋比賽，樂趣無窮！", 
          "title": "吳書葶 (晶晶) - 應外三", 
          "uuid": "4da81e42-f53a-4e1d-955d-fa11225d64b0", 
          "vote_url": "/govote/4da81e42-f53a-4e1d-955d-fa11225d64b0", 
          "votes": "139"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/jMyvsdQ/28871c7ee210.jpg", 
          "open": false, 
          "text": "我很喜歡羽球 可是蠻沒自信的 報名算是給自己一個機會試試看 希望自己真的有機會能跟大家一起打球！", 
          "title": "廖于晴 (魚鰭、晴晴) - 會計二", 
          "uuid": "5f4e4427-52ea-4239-a5f3-352153dcf1d6", 
          "vote_url": "/govote/5f4e4427-52ea-4239-a5f3-352153dcf1d6", 
          "votes": "137"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/4sbbMmP/3228b9b432e7.jpg", 
          "open": false, 
          "text": "喜歡運動\n 尤其對羽球特別有熱情\n 希望自己可以越來越強\n 也喜歡唱歌、旅遊、學習和發現新東西\n 好好享受活著的每分每秒\n 噢認識的都說我是一個很有生命力(過動)的人", 
          "title": "許瑋珊 (蛋餅) - 財法二", 
          "uuid": "c55d1689-ca48-42ff-bb86-ac0e0f10eb55", 
          "vote_url": "/govote/c55d1689-ca48-42ff-bb86-ac0e0f10eb55", 
          "votes": "123"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/xJxTVz4/6250017d72f1.jpg", 
          "open": false, 
          "text": "大家好，我沒什麼好介紹的，就是一個除了運動之外，做什麼都慢吞吞的懶人。如果在打球的時候被誇獎了，下一球常常就打不過或打不好，是一個誇不得的人，於是隊友開始決定以謾罵取代稱讚（？）", 
          "title": "吳芳綺 - 應外三", 
          "uuid": "8161ffdd-a22c-465e-bc9e-b1c0ebea4ca8", 
          "vote_url": "/govote/8161ffdd-a22c-465e-bc9e-b1c0ebea4ca8", 
          "votes": "113"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/QKFmRGY/91ce226570db.jpg", 
          "open": false, 
          "text": "從小對羽球就有很高的熱忱，羽球是我一直以來都沒斷過、也最擅長的運動，雖然沒有什麼特別的封號或成績，但在體育課的成績一直都很亮眼，老師甚至把我從女子組調到男子組，和男生一起練習。", 
          "title": "黃顗祺 (Apple) - 中文三", 
          "uuid": "6aaccc0f-4580-400c-878f-dd04e21def28", 
          "vote_url": "/govote/6aaccc0f-4580-400c-878f-dd04e21def28", 
          "votes": "74"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/QjjzQvt/b9b3bb79b8fe.jpg", 
          "open": false, 
          "text": "我從小學開始打羽球，雖然沒比過什麼大比賽，但對羽球的熱情一直持續至今，希望有多點機會能讓我打球。", 
          "title": "楊甯 - 金融一", 
          "uuid": "2e2196d7-e59d-4100-9e51-bb7714b6d00d", 
          "vote_url": "/govote/2e2196d7-e59d-4100-9e51-bb7714b6d00d", 
          "votes": "52"
        }
      ], 
      "subtitle": "女羽", 
      "vote_num": -1
    }, 
    {
      "player": [
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/6FQwMCt/6a81873cb57e.jpg", 
          "open": false, 
          "text": "嗨 我是李蘊庭 畢業於成功高中 現在是會計系羽的隊長 喜歡打羽球 但出現在排球場的頻率比羽球場高 希望有機會可以跟其他人一起打球", 
          "title": "李蘊庭 - 會計三", 
          "uuid": "f0e3752d-14fe-48e2-a510-1c1ccf688b53", 
          "vote_url": "/govote/f0e3752d-14fe-48e2-a510-1c1ccf688b53", 
          "votes": "149"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/92pfjCZ/95ed63528867.jpg", 
          "open": false, 
          "text": "想看羊騎蟲打球就投給楊琪琮吧~", 
          "title": "楊琪琮 (7bug) - 電機四", 
          "uuid": "f588b78e-fc78-4bc4-a8bb-23b173e9721b", 
          "vote_url": "/govote/f588b78e-fc78-4bc4-a8bb-23b173e9721b", 
          "votes": "139"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/4ShMCxh/e6fa1039e091.jpg", 
          "open": false, 
          "text": "哈囉！My name is 王昱翔", 
          "title": "王昱翔 (吹落山風長大的孩子) - 公行二", 
          "uuid": "6c5f0fd4-6d4f-41e4-bfbd-4e0dd6d75277", 
          "vote_url": "/govote/6c5f0fd4-6d4f-41e4-bfbd-4e0dd6d75277", 
          "votes": "131"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/z55rPs8/573b95360cd2.jpg", 
          "open": false, 
          "text": "嗨 我是鈺展", 
          "title": "林鈺展 - 電機三", 
          "uuid": "a5427b70-edb2-4526-a900-53d201b95701", 
          "vote_url": "/govote/a5427b70-edb2-4526-a900-53d201b95701", 
          "votes": "129"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/Cz2kJW3/9ab57facec9e.jpg", 
          "open": false, 
          "text": "雙打成癮症候群\n 崇越館專業卡位員\n 是個超級喜歡打羽球的人類", 
          "title": "董謹睿 - 司法二", 
          "uuid": "00d9b3f2-435d-47c1-b657-23e7afeb5317", 
          "vote_url": "/govote/00d9b3f2-435d-47c1-b657-23e7afeb5317", 
          "votes": "127"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/S3YQNzg/3f1f9b074199.jpg", 
          "open": false, 
          "text": "群組裡講話沒人理的前隊長", 
          "title": "黃溢謙 (羅蘭) - 會計四", 
          "uuid": "da85000b-e4d2-48ba-a473-4d32dc5ccef8", 
          "vote_url": "/govote/da85000b-e4d2-48ba-a473-4d32dc5ccef8", 
          "votes": "126"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/h14TYXN/eaf9474a4be2.jpg", 
          "open": false, 
          "text": "大家好 我是行政二謝睿彥 目前正積極磨練球技 盼有朝一日能比肩各路高手", 
          "title": "謝睿彥 - 行政二", 
          "uuid": "dde62d28-21aa-4c65-998e-082386d163a0", 
          "vote_url": "/govote/dde62d28-21aa-4c65-998e-082386d163a0", 
          "votes": "114"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/qJKnVvg/ff89e2cc75d1.jpg", 
          "open": false, 
          "text": "你各位同學、學長姐大家好！\n 我是法律系司法一顏致家，很高興能加入北大這個大家庭，希望能入選這次的明星賽，還請多多支持，謝謝大家🙏", 
          "title": "顏致家 - 司法一", 
          "uuid": "718dc44e-9ac3-42e4-b7f7-d24ed8deda1b", 
          "vote_url": "/govote/718dc44e-9ac3-42e4-b7f7-d24ed8deda1b", 
          "votes": "95"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/tQmtZNV/7768ac50b40e.jpg", 
          "open": false, 
          "text": "大家好，我是許弘叡，今天來要跟大家推廣韓一，消除大家對韓一的刻板印象，藉由明星賽跟大家推廣在地三峽料理", 
          "title": "許弘叡 (韓一我愛你) - 統計二", 
          "uuid": "062930c2-1851-4fb9-a24f-7b6d803fd795", 
          "vote_url": "/govote/062930c2-1851-4fb9-a24f-7b6d803fd795", 
          "votes": "89"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/VMRhyKS/dd98798a3981.jpg", 
          "open": false, 
          "text": "羽球菜雞歡迎交流\n 期待與大家菜雞互啄", 
          "title": "唐育謙 (yo謙) - 統碩一", 
          "uuid": "d748ad73-0022-4714-a232-e731b8cc4467", 
          "vote_url": "/govote/d748ad73-0022-4714-a232-e731b8cc4467", 
          "votes": "59"
        }
      ], 
      "subtitle": "男羽", 
      "vote_num": -1
    }
  ], 
  "title": "羽球"
}
const Softball_data={
  "json_list": [
    {
      "player": [
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/RzJNRNw/f4d62a68d988.jpg", 
          "open": false, 
          "text": "大家好，我是林明緯，請把票投給我", 
          "title": "林明緯 (經濟林立) - 經濟四", 
          "uuid": "83ecff3b-3829-45e8-9be0-b1098e3a755a", 
          "vote_url": "/govote/83ecff3b-3829-45e8-9be0-b1098e3a755a", 
          "votes": "200"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/zZddbQv/c708aea8e71e.jpg", 
          "open": false, 
          "text": "Came from the bottom\n 享受在場上的每一刻\n 希望能在球場留下最難忘的回憶", 
          "title": "戴愷吾 - 會計三", 
          "uuid": "a9d00639-dc66-448c-8fab-16ae18ee0aa3", 
          "vote_url": "/govote/a9d00639-dc66-448c-8fab-16ae18ee0aa3", 
          "votes": "181"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/ZccBVsg/ee632b9729b5.jpg", 
          "open": false, 
          "text": "我好想打明星賽噢！大家幫我啦！", 
          "title": "曾頊 (新的大魔王) - 不動二", 
          "uuid": "27f10398-f791-450c-9872-bfef533be358", 
          "vote_url": "/govote/27f10398-f791-450c-9872-bfef533be358", 
          "votes": "153"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/xJmPfpT/d1d6ab6342e3.jpg", 
          "open": false, 
          "text": "沒有身體的摩擦，哪有愛情的火花", 
          "title": "陳蕭喬遠 (北大浪漫-妳浪我漫) - 財政二", 
          "uuid": "020e3980-93d7-4f62-a83e-aaad9790866c", 
          "vote_url": "/govote/020e3980-93d7-4f62-a83e-aaad9790866c", 
          "votes": "147"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/dgVxFzC/16888e673222.jpg", 
          "open": false, 
          "text": "最討厭選球，不管好球壞球攻擊就對了，大學最後一年，投我一票啦", 
          "title": "謝易軒 - 統計四", 
          "uuid": "0a3517eb-1f23-4b43-be3a-99d1dc88efe9", 
          "vote_url": "/govote/0a3517eb-1f23-4b43-be3a-99d1dc88efe9", 
          "votes": "124"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/VqxGq1J/779e4c97fe56.jpg", 
          "open": false, 
          "text": "如果你們希望吉娃娃消失 投我一票", 
          "title": "梁凌佳 (北大周興哲) - 經濟二", 
          "uuid": "20d732a7-c446-41ad-8955-30fdaa60c0cf", 
          "vote_url": "/govote/20d732a7-c446-41ad-8955-30fdaa60c0cf", 
          "votes": "102"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/VDd22N0/917c94ded60f.jpg", 
          "open": false, 
          "text": "我是歷史系的許皓評，可以叫我評評，興趣是去女僕咖啡店，生日禮物還曾經收到一套女僕裝，但我沒有很宅啦！很宅就不會來打壘球了！前三年都沒什麼好的表現，希望到大四可以打出一點成績。", 
          "title": "許皓評 (萌え萌えな評評) - 歷史四", 
          "uuid": "be86ebd9-bcd4-4ad5-9def-bd30fbb43065", 
          "vote_url": "/govote/be86ebd9-bcd4-4ad5-9def-bd30fbb43065", 
          "votes": "95"
        }
      ], 
      "subtitle": "內野", 
      "vote_num": -1
    }, 
    {
      "player": [
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/7pB2wVV/540fe38893ba.jpg", 
          "open": false, 
          "text": "安安各位\n 我是會計三劉皓宇\n 來北大也不知道第幾年了\n 終於來報個壘球明星賽\n 如果你希望兄弟今年不要再亞了\n 願我們的夢想實現\n 對了\n 我棒球也是略懂些\n 想看精彩的棒球表演賽的話\n 我這邊有個建議\n 那就是投票投給我哦～⚾️", 
          "title": "劉皓宇 (北大詹子賢) - 會計三", 
          "uuid": "9a5f2469-6234-40de-8db5-55bc824a076f", 
          "vote_url": "/govote/9a5f2469-6234-40de-8db5-55bc824a076f", 
          "votes": "150"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/56qwy4p/fa6ca358bb5d.jpg", 
          "open": false, 
          "text": "大家好！我是PK,歡迎大家來明星賽跟我PK", 
          "title": "陳博凱 (PK) - 不動三", 
          "uuid": "0c76ba5f-8259-4e5b-b7ca-dae49f39d381", 
          "vote_url": "/govote/0c76ba5f-8259-4e5b-b7ca-dae49f39d381", 
          "votes": "139"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/KxkCN6g/ff5277f28080.png", 
          "open": false, 
          "text": "媽媽今年36歲\n 兒子的歲數剛好是媽媽的三分之二\n 兒子在36歲的時候\n 媽媽的歲數剛好是兒子的三分之四\n .\n .\n .\n .\n .\n .\n .\n .\n .\n .\n .\n .\n .\n 請問爸爸要關幾年?", 
          "title": "周煥鈞 (北大金城武) - 不動三", 
          "uuid": "5d560603-cc30-44f2-91f9-4323aeadb81a", 
          "vote_url": "/govote/5d560603-cc30-44f2-91f9-4323aeadb81a", 
          "votes": "132"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/vX8WHsL/14bb47a7a7e4.jpg", 
          "open": false, 
          "text": "通常都守外野 期待與各系高手切磋", 
          "title": "吳秉諭 (小吳) - 會計三", 
          "uuid": "cf605d16-9bea-479a-8c5a-3e0f80634e6e", 
          "vote_url": "/govote/cf605d16-9bea-479a-8c5a-3e0f80634e6e", 
          "votes": "131"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/6nhLV96/ecfa3769649a.jpg", 
          "open": false, 
          "text": "大家好，我是通訊三張晏銘，因為通訊系沒系壘，所以不要臉的跑去統計系壘抱大腿，希望未來北大系壘能更加蓬勃發展。", 
          "title": "張晏銘 (北大敗投王) - 通訊三", 
          "uuid": "6ffe0297-6e8b-4fd4-bbcf-63c74aab5e7c", 
          "vote_url": "/govote/6ffe0297-6e8b-4fd4-bbcf-63c74aab5e7c", 
          "votes": "97"
        }
      ], 
      "subtitle": "外野", 
      "vote_num": -1
    }, 
    {
      "player": [
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/Pg5ZCDQ/d9efc1dd75ae.jpg", 
          "open": false, 
          "text": "我要繼承小寶的生涯。", 
          "title": "洪揚晉 - 不動三", 
          "uuid": "d1e7e7da-d440-4eff-8e90-49c360cbef83", 
          "vote_url": "/govote/d1e7e7da-d440-4eff-8e90-49c360cbef83", 
          "votes": "117"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/ZGsPgBf/1b94be540b75.jpg", 
          "open": false, 
          "text": "好想打明星賽～", 
          "title": "陶俊成 - 統計四", 
          "uuid": "ea15ef63-b1c5-4c9d-9200-0b5f419b40c0", 
          "vote_url": "/govote/ea15ef63-b1c5-4c9d-9200-0b5f419b40c0", 
          "votes": "112"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/G2rh06K/dc808220b4b4.png", 
          "open": false, 
          "text": "嗨嗨 我是陳蕭喬遠的朋友", 
          "title": "李亮頤 - 統計二", 
          "uuid": "341057f5-3e5e-49fe-ab2c-504ef449c055", 
          "vote_url": "/govote/341057f5-3e5e-49fe-ab2c-504ef449c055", 
          "votes": "91"
        }, 
        {
          "btn_text": "超過投票時間", 
          "image": "https://i.ibb.co/9rBjhRG/abd84ce61ff3.jpg", 
          "open": false, 
          "text": "哈囉 我是歷史四的向宇謙\n 守備位置是投手\n 投球表現堪稱穩定(掉分)\n 但球是O的 什麼都有可能\n 最後一年了 希望有機會圓個夢^_^", 
          "title": "向宇謙 - 歷史四", 
          "uuid": "b6c01ad8-2f39-4693-89dc-333448d9425b", 
          "vote_url": "/govote/b6c01ad8-2f39-4693-89dc-333448d9425b", 
          "votes": "58"
        }
      ], 
      "subtitle": "投手", 
      "vote_num": -1
    }
  ], 
  "title": "壘球"
}
export default {
    api_url,
    vote_list,
    leader_board_data,
    Basketball_data,
    Volleyball_data,
    Badminton_data,
    Softball_data,
  }
</script>